import { css } from "@emotion/react"
import { useEffect } from "react"
import { GetBaysDto, useBayPrograms } from "../client"
import { ProgramCard } from "./ProgramCard"

const bayPage = css`
    height: 100vh;
    background: #2e3234;
    overflow: hidden;
    font-family: "Repro", tahoma;
`
const gridContainer = css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 6fr 5fr 2fr 8fr 3fr;
    grid-template-areas:
        "header header header header"
        "title title title title"
        "price price price price"
        "details details details details"
        "duration duration duration duration";
    align-items: stretch;
`
const header = css`
    grid-area: header;
    color: #ffffff;
    padding: 30px 0px 0px 50px;
    font-size: 88px;
    font-weight: 350;
`

// Written to be previewed in 1080x690, and refreshed every minute
export function BayPage({ bay }: { bay: GetBaysDto }) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { data, refresh } = useBayPrograms(bay.slug)
    useEffect(() => {
        const interval = setInterval(refresh, 60_000)
        return () => clearInterval(interval)
    }, [refresh])
    if (!data) return <></>

    return (
        <div css={[bayPage, gridContainer]}>
            <div css={header}>Vaskeprogram</div>
            {data.slice(0, 4).map((program, i) => (
                <ProgramCard key={program.id.valueOf()} program={program} index={i} />
            ))}
        </div>
    )
}
