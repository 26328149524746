import { GetBaysDto } from "../client"

export function Landing({ bays }: { bays?: GetBaysDto[] }) {
    return (
        <ul>
            {bays?.map((bay) => (
                <li key={bay.id.valueOf()}>
                    <a href={bay.slug}>{bay.name}</a>
                </li>
            ))}
        </ul>
    )
}
